<template>
  <v-app>
      <v-app-bar app color="#222222">
      <v-toolbar-title>
        <router-link to="/" class="text-white text-lg font-bold">BACC</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="openTwitter" class="cursor-pointer" icon="mdi-twitter"></v-icon>
      <v-btn color="white" @click="openMint" class="cursor-pointer ml-4" >Mint</v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <template v-if="item">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="card">
                <v-img :src="item.image" :alt="`Item ${item.id} Image`"></v-img>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card class="attributes-card">
                <v-card-title class="text-2xl font-bold mb-4">{{ item.name }}</v-card-title>
                <v-row>
                  <v-col v-for="attribute in item.attributes" :key="attribute.trait_type" cols="6">
                    <v-card class="attribute-item">
                      <v-card-text class="text-sm font-semibold text-gray-600">{{ attribute.trait_type }}</v-card-text>
                      <v-card-text class="text-lg font-bold">{{ attribute.value }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <v-row v-else>
          <v-col>
            <p>Loading...</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- İsteğe bağlı: Footer içeriği buraya eklenebilir -->
    </v-footer>
  </v-app>
</template>

<script>
import bcacData from "@/bcac/bcac.json";

export default {
  data() {
    return {
      item: null,
      drawer: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const itemId = this.$route.params.id;
      this.item = bcacData.find((item) => item.id == itemId);
    },
    openTwitter() {
      window.open('https://twitter.com/baccbtc', '_blank');
    },
    openMint() {
      window.open('ordinalgenesis.xyz', '_blank');
    }
  }
};
</script>

<style scoped>
  body {
    background-color: #222222;
    color: white;
  }

  .card {
    border: 2px solid #000;
  }

  .attributes-card {
    /* Diğer stillendirmeler */
  }

  .attribute-item {
    /* Diğer stillendirmeler */
  }

  .cursor-pointer {
    cursor: pointer;
  }
</style>
