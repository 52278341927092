<template>
  <v-app>
    <v-app-bar app color="#3333">
      <v-toolbar-title>
        <router-link to="/" class="text-white text-lg font-bold">BACC</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon color="white" @click="openTwitter" class="cursor-pointer" icon="mdi-twitter"></v-icon>
      <v-btn color="white" @click="openMint" class="cursor-pointer ml-4" >Mint</v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <!-- Video Player -->
        <v-row>
          <v-col>
            <v-card>
              <!-- YouTube video embed -->
              <iframe
                width="100%"
                height="400"
                :src="youtubeVideoLink"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </v-card>
          </v-col>
        </v-row>

        <!-- Card Grid -->
        <v-row>
          <v-col v-for="item in displayedItems" :key="item.id" cols="12" sm="6" md="4" lg="3">
            <v-card @click="goToDetail(item.id)" class="mb-4">
              <v-img :src="item.image" :alt="`Item ${item.id} Image`" :style="{ animation: 'translateXAnimation 10s infinite' }" loading="lazy"></v-img>
              <v-card-title>{{ item.name }}</v-card-title>
              <v-card-subtitle>{{ item.category }}</v-card-subtitle>
              <v-card-text>{{ item.description }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Load More Button -->
        <v-row>
          <v-col>
            <v-btn v-if="displayedItems.length < items.length" @click="loadMore" color="accent">Load More</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import bcacData from "@/bcac/bcac.json";

export default {
  data() {
    return {
      items: [],
      displayedItems: [],
      itemsPerPage: 50,
      currentPage: 1,
      youtubeVideoLink: "https://www.youtube.com/embed/cE2E6bj0Hg4", // YouTube video linkini buraya ekleyin
    };
  },
  mounted() {
    this.items = bcacData.map((item, index) => ({ ...item, id: index + 1 }));
    this.loadMore();
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: "detail", params: { id } });
    },
    loadMore() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.displayedItems = this.displayedItems.concat(this.items.slice(start, end));
      this.currentPage++;
    },
    openTwitter() {
      window.open('https://twitter.com/baccbtc', '_blank');
    },
    openMint() {
      window.open('ordinalgenesis.xyz', '_blank');
    },
  },
};
</script>

<style scoped>
body {
  background-color: #222222;
  color: white;
}

@keyframes translateXAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

.v-application {
  background-color: #222222;
}

.v-application--wrap {
  background-color: #222222;
}

a {
  text-decoration: none;
  color: white;
}

.v-app-bar {
  margin-bottom: 20px;
}
* {

}
</style>
